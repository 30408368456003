*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica, sans-serif;
  color: #fff;
}

*:before,
*:after {
  box-sizing: inherit;
}

h1 {
  font-weight: normal;
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
  cursor: pointer;
}

::selection {
  background: #1DBC4D;
  /* color: #fff; */
}

input,
select,
textarea {
  color: #000;
}

textarea:focus,
input:focus {
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}